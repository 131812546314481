<template>
  <div class="disclosure">
    <h1>California AB 1305 Disclosure</h1>
    <p>
      All energy delivered from Electrify America’s charging stations to electric vehicles is backed by 100% renewable
      energy. Electrify America substantiates this claim in California through participation within the Low Carbon Fuel
      Standard (LCFS) Program, under which it reports all energy delivered during charging sessions at its stations in
      California. The LCFS Program has a process for pairing energy use with Renewable Energy Certificates (RECs) from
      zero-carbon emissions (carbon intensity (CI)) sources, such as solar and wind, to justify a zero-carbon score
      within the program. The RECs which Electrify America currently uses to demonstrate compliance with the LCFS
      program include RECS generated as a result of its virtual power purchase agreement from its Electrify America
      Solar Glow&trade; 1 solar photovoltaic plant, located in San Bernardino County, California, and may also include
      other RECs delivered to a California Balancing Authority in compliance with LCFS.
    </p>
    <p>
      Electrify America works as needed with a third party to procure and retire RECs apart from those generated at the
      Electrify America Solar Glow&trade; 1 solar photovoltaic plant. These RECs must meet a range of stringent
      requirements, including geographic origin, a vintage requirement of two quarters or less, and retirement within
      the Western Renewable Energy Generation Information System (WREGIS) tool. Electrify America also uses other RECs
      outside the LCFS Program to substantiate its claims in markets outside of California.
    </p>
    <p>
      Validity of RECs and confirmation of their retirement is accomplished within the WREGIS system, an independent
      web-based tracking system for environmental attributes of electricity operated by the Western Electricity
      Coordinating Council (WECC).
    </p>
    <p>
      Electrify America relies on RECs exclusively from zero-CI sources as established in the LCFS Program, which
      identifies zero-CI electricity as electricity from “100% zero-CI sources, which include: eligible renewable energy
      resources as defined in the California Public Utilities Code sections 399.11-399.36, excluding biomass,
      biomethane, geothermal, and municipal solid waste.” Electrify America relies on California state determinations
      regarding zero-CI nature of these energy sources.
    </p>
  </div>
</template>

<script>
export default {
  name: 'california-voluntary-carbon-market-disclosure',
  metaInfo: {
    title: 'California Voluntary Carbon Market Disclosure (AB 1305) | Electrify America',
    link: [
      { rel: 'canonical', href: 'https://www.electrifyamerica.com/california-voluntary-carbon-market-disclosure/' },
    ],
  },
};
</script>
